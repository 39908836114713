<template>
   <div class="form-group">
        <button type="button" class="btn btn-primary"> <i class="fa fa-send"></i>
        Send Quote</button>	
        <button class="btn btn-primary" title="Save Draft">
            <i class="fa fa-save"></i>
            Save Draft
            </button>
        <button class="btn btn-primary" type="button">
                Show Standard Response Form
        </button>
    </div>
</template>

<script>
 export default {
  name: 'QuoteButtonsComponents',
  components: {
     
  },
  data: function(){
      return{
      
      }
  }

}
</script>