<template>

   <tr v-bind:class="replyType">
              <td class="white-bg">&nbsp;</td> 
         
              <td style="width:376px;">
               <button class="btn btn-xs btn-link btn-expand" v-on:click = " showexpanded1 = !showexpanded1">
                   <i class="fa fa-plus-circle" v-if="!showexpanded1" v-show="!showexpanded1"></i>
                   <i class="fa fa-minus-circle" v-if="showexpanded1" v-show="showexpanded1"></i>
                </button>
              [ref#: 42733-42733] I need help picking a camera</td>
              <td>	All Topics</td>
              <td>
                  <small v-bind:class="statusbtnclass">
                    <i v-if="showstatusbtnicon" v-bind:class="statusbtniconclass" ></i>
                    {{statusbtntext}}
                  </small>
              </td>
             
              <td>	Jacob Smith	</td>
            
              <td>2016-07-18 9:36:26 AM</td>
            </tr>
            <tr class="expandedrowcontent" v-bind:class="replyType" v-if="showexpanded1">
             <td class="white-bg">&nbsp;</td>
              <td colspan="7">
                 <ViewModeContent v-if="viewmodecontent" v-bind:showGetThis = "showgethis" v-bind:showAnswer = "showanswer"></ViewModeContent>

                 <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>

                 <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>

                 <CreateTicketContent v-if="createticketcontent"></CreateTicketContent>

                 <ForwardContent v-if="forwardccopyontent"></ForwardContent>

                 <GetThisContent v-if="getthiscontent"></GetThisContent> 
              </td> 
            </tr>
</template>

<script>
import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';

import ViewModeContent from '../components/ViewModeContent.vue';
import CreateNoteContent from '../components/CreateNoteContent.vue';
import AgentEmailContent from '../components/AgentEmailContent.vue';
import CreateTicketContent from '../components/CreateTicketContent.vue';
import ForwardContent from '../components/ForwardContent.vue';
import GetThisContent from '../components/GetThisContent.vue';

import CaseProperty from '../components/CaseProperty.vue';
import StatusButton from '../components/StatusButton.vue';


import Multiselect from '@vueform/multiselect'

    export default {
      name: 'Expanded Row Case View',
      props: {replyType:String,showGetThis: Boolean, statusBtnText:String, statusBtnClass:String, showstatusBtnIcon:Boolean,statusBtnIconClass:String, typeBtnText:String, typeBtnClass:String, showtypeBtnIcon:Boolean, typeBtnIconClass:String,typeBtnIconClassGroup:String,typeGroupIcon:Boolean, showAnswer:Boolean},
      components: {
        Multiselect,
        InteractionPropertyTab,
        DetailsandattachmentsTabContent,
        ViewModeContent,
        CreateNoteContent,
        AgentEmailContent,
        CreateTicketContent,
        ForwardContent,
        GetThisContent,
        CaseProperty,
        StatusButton
      },
      data: function(){
          return{
            showBCC : true,
            date: new Date(),
            timezone: '',
            showexpanded1: false,
            viewmodecontent : true,
            createnotecontent : false,
            agentemailcontent : false,
            createticketcontent : false,
            forwardcontent  : false,
            getthiscontent : false,
            replyType : this.replyType,
            showGetThis : this.showGetThis,
            statusbtntext:this.statusBtnText, 
            statusbtnclass:this.statusBtnClass, 
            showstatusbtnicon:this.showstatusBtnIcon,
            statusbtniconclass:this.statusBtnIconClass, 
            typebtntext:this.typeBtnText, 
            typebtnclass:this.typeBtnClass,
            showtypebtnicon:this.showtypeBtnIcon, 
            typebtniconclass:this.typeBtnIconClass,
            typebtniconclassgroup: this.typeBtnIconClassGroup,
            typegroupicon : this.typeGroupIcon,
            showgethis : this.showGetThis,
            showanswer : this.showAnswer
          }
      },
      mounted(){
           
           this.emitter.on("show-view-details", Data => {
              this[Data.displaySegment] = true;
              this.viewmodecontent = false;
            });
            this.emitter.on("hide-view-details", Data => {
              this[Data.displaySegment] = false;
              this.viewmodecontent = true;
            });
      }
    }
</script>