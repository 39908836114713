<template>
   
    <div class="row">
      
       <div class="col-md-12">
           <div class="message-box overflow-box">
           <div class="message-box-body">
               <div class="clear"></div>
               
               <div class="col-md-6">
                   <table class="table">
                       <tbody>
                           <tr>
                               <td><strong>Reference</strong></td>
                               <td></td>
                           </tr>
                           <tr>
                               <td><strong>Date of Payment</strong></td>
                               <td></td>
                           </tr>
                           <tr>
                               <td><strong>Amount Paid</strong></td>
                               <td>$</td>
                           </tr>
                           <tr>
                               <td><strong>Payment Method</strong></td>
                               <td> (####-####-####-)</td>
                           </tr>
                           <tr>
                               <td><strong>Confirmation Number</strong></td>
                               <td></td>
                           </tr>
                       </tbody>
                   </table>
                   </div>
             
           </div>
           </div>
       </div>
   </div>
   </template>
   <style src="@vueform/toggle/themes/default.css"></style>
   <script>
   import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
   import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';
   
   
   import StatusAudit from '../components/StatusAudit.vue';
   import FullCaseHistory from '../components/FullCaseHistory.vue';
   import Toggle from '@vueform/toggle'
   
   import Multiselect from '@vueform/multiselect'
   
       export default {
         name: 'Expanded Row',
         props: {showGetThis:Boolean,showPaymentStatus:Boolean,showAnswer:Boolean},
         components: {
           Multiselect,
           InteractionPropertyTab,
           DetailsandattachmentsTabContent,
           StatusAudit,
           FullCaseHistory,
           Toggle
           
         },
         mounted(){
              if(this.showGetThis == 0){
                  this.showget = false;
              }else{
                 this.showget = true;
              }
   
               if(this.showAnswer == 0){
                  this.showanswer = false;
              }else{
                 this.showanswer = true;
              }
           },
         data: function(){
             return{
               showBCC : true,
               date: new Date(),
               timezone: '',
               showexpanded1: false,
               toggleMore : true,
               statusaudit : false,
               fullcasehistory : false,
               palintext: true,
               showget : Boolean(this.showGetThis),
               showanswer : Boolean(this.showGetThis),
               showconfirmdelete:false
             }
         },
          methods: {
               show(segment){
                 this.emitter.emit("show-view-details",{displaySegment:segment});
               }
               
           }
       }
   </script>
   
   