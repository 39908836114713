<template>
   <div class="row">
      <div class="col-md-12">
         <div class="nav-tabs-custom">
            <ul class="nav nav-tabs">
               <li class="nav-item"><a class="nav-link active" data-bs-toggle="tab" data-bs-target="#tab_detail_1" role="tab" aria-controls="formbody"  aria-selected="true"   uib-tooltip="This interaction has file attachments." tooltip-placement="bottom" ><i class="fa fa-paperclip"></i><span data-bs-toggle="tooltip" data-bs-placement="bottom" title="This interaction has file attachments.">Details & Attachments</span><span class="new-inside"></span></a></li>
               <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab_property_2" role="tab" aria-controls="formbody"  aria-selected="true" uib-tooltip=" There are property values set for this interaction." tooltip-placement="bottom"><i class="fa fa-fw fa-exchange"></i>  <span data-bs-toggle="tooltip" data-bs-placement="bottom" title="There are property values set for this interactions.">Interaction Properties</span><span class="new-inside"></span></a> </li>
               <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab_property_3" role="tab" aria-controls="formbody"  aria-selected="true" uib-tooltip="There are private comments for this interaction." tooltip-placement="bottom" ><i class="fa fa-fw fa-pencil-square-o"></i> <span>Private Comments </span></a> </li>
            </ul>
            <div class="tab-content">
               <div class="tab-pane active" id="tab_detail_1">
                  <DetailsandattachmentsTabContent></DetailsandattachmentsTabContent>
               </div>
               <div class="tab-pane" id="tab_property_2">
                  <InteractionPropertyTab></InteractionPropertyTab>
               </div>
               <!--tab 3 start-->
               <div class="tab-pane" id="tab_property_3">
                  Please show me the other ranges in Plasma tv.
               </div>
               <!--tab 3 end-->	
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';
import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
import Multiselect from '@vueform/multiselect'

  export default {
  name: 'QuoteQuestionDetailsTabComponents',
   components: {
      DetailsandattachmentsTabContent,
      InteractionPropertyTab,
      Multiselect
  },
  mounted(){
          
      },
  data: function(){
     
  }

}

</script>