<template>
<div class="notdatatable form-inline mbottom-15">
   <div class="table-controls table-columns">
      <div class="btn-group"><button class="btn btn-default" type="button"><span>Excel</span></button><button class="btn btn-default button-columns" type="button"><span>Column visibility</span></button></div>
   </div>
   <div class="table-controls table-pagesize table-columns">
      <label>
         Show 
         <select class="form-control input-xs">
            <option>5</option>
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>250</option>
            <option>500</option>
            <option>1000</option>
         </select>
         entries 
      </label>
   </div>
   <div class="table-controls table-pagesize"><label>Show past <input type="text" class="form-control input-xs" style="width: 50px;"> days</label></div>
   <div class="table-controls table-filter">Filter Interactions <input type="search" class="form-control" id="Main_MyDashboard_Recent_TableControls_Filter"></div>
</div>
<table class="table table-hover">
   <thead>
      <tr role="row">
        <th class="no-sort sorting_asc" name="name" tabindex="0" aria-controls="historyTabCaseView" rowspan="1" colspan="1"></th>
        <th>
            Subject
        </th>
        <th>
            	
          Case Topic
        </th>
        <th>
           Case Status
        </th>
        <th>
            Resolved By
        </th>
        <th>
            Date
        </th>
      </tr>
   </thead>
   <tbody>
     <tr role="row" class="odd">
        <td name="name" class="sorting_1">
            <button class="btn btn-xs btn-link text-default btn-expand" title="Operations" v-on:click="toggleRow('1')">
                <i class="fa fa-plus-circle" v-if="!row1"></i>
                <i class="fa fa-minus-circle" v-if="row1"></i>
            </button>
        </td>
        <td>
            [ref#: 42733-42733] I need help picking a camera
        </td>
        <td>
            All Topics
        </td>
        <td>
            <small class="bg-red label">Unsent</small>
        </td>
       
        <td>
            Jacob Smith
        </td>
       
        <td>
            2016-07-18 9:36:26 AM
        </td>
      </tr>
      <ExpandedRowCaseView v-if="row1" replyType ="bg-customer-reply" showGetThis = "0" statusBtnText="PublicNote" statusBtnClass="label  bg-green-disabled" showstatusBtnIcon = "0" typeBtnText="Chat" typeBtnClass="label bg-blue disabled label-with-icon" showtypeBtnIcon = "1" typeBtnIconClass="fa fa-comments" showAnswer = "0"></ExpandedRowCaseView>
      <ExpandedRowCaseView v-if="row1" replyType ="bg-agent-reply" showGetThis = "1"  statusBtnText="Queued" statusBtnClass="label bg-red-disabled" showstatusBtnIcon = "0" typeBtnText="Unsent Response" typeBtnClass="bg-green label" showtypeBtnIcon = "1" typeBtnIconClass="fa fa-reply" typeBtnIconClassGroup="fa fa-times-circle-o" typeGroupIcon="1" showAnswer = "1"></ExpandedRowCaseView>                      
     
   </tbody>
</table>
<div id="Main_MyDashboard_Recent_TableInfo">
   <div class="notdatatable table-info">Showing 1 to 5 of 29 entries</div>
   <div class="dataTables_paginate notdatatable table-pagination text-end">
      <ul class="pagination">
         <li><a href="">Previous</a></li>
         <li class="active"><a href="">1</a></li>
         <li><a href="">Next</a></li>
      </ul>
   </div>
</div>
</template>

<script>
import ExpandedRowCaseView from '../components/ExpandedRowCaseView.vue';

export default {
  name: 'Case View',
  components: {
    ExpandedRowCaseView,
  },
  data() {
      return{
          row1 : false,
          row2 : false,
      }
  },
  methods: {
    toggleRow(i) {
        this['row'+i] = !this['row'+i] 
    }
  }
  
}
</script>