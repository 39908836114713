<template>
  <div class="nav-tabs-custom">
    <ul class="nav nav-tabs">
        <li class="nav-item"><a class="nav-link active" type="button" id="form-tab" data-bs-toggle="tab" data-bs-target="#qouteform" role="tab" aria-controls="formbody"  aria-selected="true">Quote Form</a></li>
        <li class="nav-item"><a class="nav-link" type="button" id="preview-tab" data-bs-toggle="tab" data-bs-target="#qoutepreview" role="tab" aria-controls="previewbody"  aria-selected="false">Quote Preview<br></a></li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane active" id="qouteform">
        <div class="row form-horizontal">
            <div class="col-md-8">
              <div class="form-group">
                <label class="col-3 control-label text-left">To</label>
                <div class="col-9" > 
                  <select class="form-control select2">
                    <option selected="selected">john.doe@example.com</option>
                    <option>albert.metricman@example.com</option>
                    <option>julie.persuavia@example.com</option>
                    <option>howard.smith@example.net</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-3 control-label text-left">Select A Template For Your Quote</label>
                <div class="col-9">
                  <select class="form-control select2">
                    <option selected="selected">Quote Template</option>
                  </select>
                </div>
              </div>
             
              <div class="nested-panel-heading skill-heading">
                <h3 class="nested-title">Quote Inputs</h3>
              </div>
              <div class="form-group">
                  <label class="col-3 control-label text-left">Quote Number</label>
                  <div class="col-9">
                    <input class="form-control" type="text">
                  </div>
              </div>
              <div class="form-group">
                  <label class="col-3 control-label text-left">Item Number</label>
                  <div class="col-9">
                    <input class="form-control" type="text">
                  </div>
              </div>
              <div class="form-group">
                  <label class="col-3 control-label text-left">Item Description</label>
                  <div class="col-9">
                    <input class="form-control" type="text">
                  </div>
              </div>
              <div class="form-group">
                  <label class="col-3 control-label text-left">Unit Price</label>
                  <div class="col-9">
                    <input class="form-control" type="text">
                  </div>
              </div>
              <div class="form-group">
                  <label class="col-3 control-label text-left">Total Price</label>
                  <div class="col-9">
                    <input class="form-control" type="text">
                  </div>
              </div>
              <div class="form-group">
                  <label class="col-3 control-label text-left">Agent Comments</label>
                  <div class="col-9">
                    <textarea class="form-control"></textarea>
                  </div>
              </div>
            </div>
            <div class="col-md-4">
               <div class="form-group">
                    <div class="col-md-12">
                        <label class="drop-box">
                        Drop files here or click to upload
                        <input type="file" style="display:none;" multiple="">
                        </label>
                        <div>(Maximum 10 MB upload at one time.)</div>
                        <div class="imageuploadify">
                        <!-- ngRepeat: file in files -->
                        </div>
                    </div>
                </div>
            </div>
          </div>
      </div>
      <div class="tab-pane" id="qoutepreview">
         <div class="box">
           <div class="box-header with-border">
                <h3 class="box-title head-title-padding">Original Question</h3>
            </div>
            <div class="box-body">
                <QuotePreview></QuotePreview>
            </div>
         </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuotePreview from '../components/QuotePreview.vue';

  export default {
  name: 'QuoteResponseBodyComponents',
   components: {
      QuotePreview
  },
  data: function(){
     
  }

}

</script>