<template>
  <div class="row">
    <div class="col-md-12">
       <div class="box box-info">
            <div class="box-header with-border">
                <i class="fa fa-history"></i>
                <h3 class="box-title head-title-padding">Contact History</h3>
                <div class="btn-group pull-right" id="realtime" data-toggle="btn-toggle">
                    <a class="btn btn-default"  v-bind:class="(chronologicalview)?'btn-primary active':''"  v-on:click="chronologicalview = true; caseview = false; summaryview = false;">Chronological View</a>
                    <a class="btn btn-default " v-bind:class="(caseview)?'btn-primary active':''"  href="unsafe:javascript:void(0)" v-on:click="chronologicalview = false; caseview = true; summaryview = false;">Case View</a>
                    <a class="btn btn-default " v-bind:class="(summaryview)?'btn-primary active':''"  href="unsafe:javascript:void(0)" v-on:click="chronologicalview = false; caseview = false; summaryview = true;">Summary View</a>
                    
                  </div>
                <div class="pull-right show-notifications">
                    <label class="incoming-ques"> <iservice-switch switched="toggle.switch6"><label class="switch"><input type="checkbox" ng-model="toggle.switch6" iservice-switch="" class="ng-pristine ng-untouched ng-valid ng-empty" style=""><i></i><ng-transclude></ng-transclude></label></iservice-switch>Show Notifications</label>
                </div> 
            </div>
            <div class="box-body table-responsive">
                <ChronologicalView v-if="chronologicalview"></ChronologicalView>
                <CaseView v-if="caseview"></CaseView>
                <SummaryView v-if="summaryview"></SummaryView>
            </div>
       </div>
    </div>
  </div>
</template>

<script>
import ChronologicalView from '../components/ChronologicalView.vue';
import CaseView from '../components/CaseView.vue';
import SummaryView from '../components/SummaryView.vue';
export default {
  name: 'HistoryTabContent',
  components: {
    CaseView,
    ChronologicalView,
    SummaryView
  },
 data() {
      return{
         chronologicalview:true,
         summaryview :false,
         caseview :false
      }
  },
  methods: {
   
  }
  
}
</script>