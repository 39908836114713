<template>
 <div class="form-group message-body-container">
        <table align="center" cellpadding="0" cellspacing="0" width="100%" style="background: #e8e6e6">
            <tbody>
                <tr>
                  <td align="left" valign="top" width="100%">
                    <center>
                      <table cellspacing="0" cellpadding="0" width="100%" bgcolor="#e8e6e6">
                        <tbody>
                          <tr>
                          <td width="100%" height="80" valign="top" style="text-align: center; vertical-align: middle">
                            <center>
                              <table cellpadding="0" cellspacing="0" width="600" style="height: 80px; background: #194576">
                                <tbody><tr>
                                  <td style="vertical-align: middle; padding-left: 12px">
                                    <img src="https://image.ibb.co/jHwYtS/eshoplogo.png" alt="logo" border="0">
                                  </td>
                                </tr>
                              </tbody></table>
                            </center>
                          </td>
                        </tr>
                      </tbody></table>
                    </center>
                  </td>
                </tr>
                <tr>
                  <td align="center" valign="top" width="100%" style="background-color: #e8e6e6; padding-bottom: 5px">
                    <center>
                      <table cellspacing="0" cellpadding="0" width="600" style="background: #fff; line-height: 21px">
                        <tbody><tr>
                          <td style="padding: 10px 15px 0px; text-align: left; color: #777"> <br>
                            <span style="font-size: 14px; font-weight: bold; color: #333333">Your eShop product quote is below</span>
                            <br>  <br>
              Thanks for requesting a price quote from eShop. All quotes are valid for 30 days, unless otherwise stated in the quotation.  If you have any questions please contact us at 217-555-5555 during business hours (7am – 7pm ET), or reply to this email with your question. You can also chat with one of our sales specialists during normal business hours by <a href="https://qa.iServiceCRM.com/f/chat" style="color: #2b23c6">clicking here.</a> 
              
                            <br><br>
                          </td>
                        </tr>
                        <tr>
                          <td style="border: 1px solid #e5e5e5; background: #fff; border-right: 0px; border-left: 0px">
                            <table cellpadding="0" cellspacing="0" width="100%" style="line-height: 21px">
                              <tbody><tr>
                                <td style="vertical-align: top; width: 278px; padding-right: 15px; padding-left: 15px; border-right: 1px solid #e5e5e5">
                                  <table cellpadding="0" cellspacing="0" width="100%">
                                    <tbody><tr>
                                      <td>
                                        <table cellspacing="0" cellpadding="0" width="100%" style="border-collapse: separate !important; color: #777">
                                          <tbody><tr>
                                            <td>
                                              <span style="color: #26a4d3; font-size: 14px; font-weight: bold; padding: 5px 0">Quote Number: </span><br>3250<br>
                                              <span style="color: #26a4d3; font-size: 14px; font-weight: bold; padding: 5px 0">Quote Date: </span><br>2/12/2019<br>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      </td>
                                    </tr>
                                  </tbody></table>
                                </td>
                                <td style="vertical-align: top; width: 278px; padding-left: 15px">
                                  <table cellpadding="0" cellspacing="0" width="100%" style="line-height: 21px">
                                    <tbody><tr>
                                      <td>
                                        <table cellspacing="0" cellpadding="0" width="100%" style="border-collapse: separate !important; color: #777">
                                          <tbody><tr>
                                            <td>
                                              <span style="color: #26a4d3; font-size: 14px; font-weight: bold; padding: 5px 0">Quote Total:</span><br>
                                              $ 423.75</td>
                                          </tr>
                                        </tbody></table>
                                      </td>
                                    </tr>
                                  </tbody></table>
                                </td>
                              </tr>
                            </tbody></table>
                          </td>
                        </tr>
                      </tbody></table>
                    </center>
                  </td>
                </tr>
                
                <tr>
                  <td align="center" valign="top" width="100%">
                    <center>
                      <table cellpadding="0" cellspacing="0" width="600" style="background-color: #ffffff; line-height: 21px; border-top: 1px solid #e5e5e5; border-bottom: 1px solid #e5e5e5">
                          <tbody><tr>
                            <td style="padding-left: 15px; padding-right: 15px">
                              <table cellspacing="0" cellpadding="0" width="100%">
                                <tbody><tr>
                                  <td width="100" style="text-align: left; border-bottom: 1px solid #cccccc; color: #4d4d4d; font-weight: 700; padding-bottom: 5px">
                                      Qty.
                                  </td>
                                  <td width="100" style="text-align: left; border-bottom: 1px solid #cccccc; color: #4d4d4d; font-weight: 700; padding-bottom: 5px">
                                    Item No.
                                  </td>
                                  <td width="200" style="text-align: left; border-bottom: 1px solid #cccccc; color: #4d4d4d; font-weight: 700; padding-bottom: 5px">
                                    Desc.
                                  </td>
                                  <td width="100" style="text-align: left; border-bottom: 1px solid #cccccc; color: #4d4d4d; font-weight: 700; padding-bottom: 5px">
                                    Unit Price
                                  </td>
                                  <td width="97" style="text-align: left; border-bottom: 1px solid #cccccc; color: #4d4d4d; font-weight: 700; padding-bottom: 5px">
                                    Total Price
                                  </td>
                                </tr>
                                <tr>
                                  
                                  <td style="padding-top: 20px; text-align: left; vertical-align: top; color: #777">
                                    1 
                                    </td>
                                    <td style="padding-top: 20px; text-align: left; vertical-align: top; color: #777">
                                    1756
                                    </td>
                                    <td style="padding-top: 20px; text-align: left; vertical-align: top; color: #777">
                                      Canon EOS Rebel T6
                                    </td>
                                    <td style="padding-top: 20px; text-align: left; vertical-align: top; color: #777">
                                      $ 399
                                    </td>
                                    <td style="padding-top: 20px; text-align: left; vertical-align: top; color: #777">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; $ 423.75</td>
                                  </tr>  
                              </tbody></table>
                            </td>
                          </tr>
              
                      </tbody></table>
                    </center>
                  </td>
                </tr>
                  
                  <tr>
                  <td align="center" valign="top" width="100%">
                    <center>
                      <table cellpadding="0" cellspacing="0" width="600" style="background: #fff">
                          <tbody><tr>
                            <td style="padding: 20px 15px 20px 15px">
                              <table cellspacing="0" cellpadding="0" width="100%" style="line-height: 21px">
                                <tbody><tr>
                                  <td width="300" style="color: #26a4d3; font-size: 16px; font-weight: bold; text-align: left">
                                      Agent Comments
                                  </td>
                                  </tr>
                                  <tr>
                                    <td style="text-align: left; color: #777"><span style="padding-top: 20px; text-align: left; vertical-align: top; color: #777">This is the best price we will have on this camera all year.</span></td>
                                  </tr>
                              </tbody></table>
                            </td>
                          </tr>
              
                      </tbody></table>
                    </center>
                  </td>
                </tr>
                
                <tr>
                  <td align="center" valign="top" width="100%" style="background: #e8e6e6; height: 100px">
                    <center>
                      <table cellspacing="0" cellpadding="0" width="600" style="background: #26a4d3; color: #fff; line-height: 21px">
                        <tbody><tr>
                          <td style="padding: 25px 25px 0px 25px; font-size: 18px; font-weight: 700px">
                            Please Note
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 10px 25px 0px">
                            <ul style="padding: 0; margin: 15px">
                                <li>We accept credit cards (Mastercard, VISA, American Express, Discover, and Diners Club International), PayPal, and Bitcoin payment methods.</li>
                                <li>A delivery date will be established after payment is confirmed. </li>
                                <li>Configuration changes to the product quoted will require a new price quote. </li>
                                <li>We guarantee the lowest price on any product we sell. Period.</li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td style="font-size: 18px; font-weight: bold; text-align: center; color: #fff">YOUR FEEDBACK IS IMPORTANT TO US</td>
                        </tr>
                        <tr>
                          <td style="font-size: 14px; text-align: center; color: #89d2ea; padding-bottom: 50px">Let us know how we're doing.</td>
                        </tr>
                        <tr>
                          <td style="font-size: 14px; text-align: center; color: #fff; background: #292828; padding: 15px">
                            <a href="" style="margin-right: 15px; display: inline-block"><img src="https://image.ibb.co/mcUoLS/facebook.png"></a>
                            <a href="" style="margin-right: 15px; display: inline-block"><img src="https://image.ibb.co/h8NVZn/twitter.png"></a>
                            <a href="" style="margin-right: 15px; display: inline-block"><img src="https://image.ibb.co/hQReS7/linkedin.png"></a>
                            <a href="" style="display: inline-block"><img src="https://image.ibb.co/fOjYn7/instagram.png"></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                </center>
                    
            </td>
        </tr>
      </tbody>
        </table>
      </div>
</template>

<script>

  export default {
  name: 'QuotePreviewComponents',
   components: {
       
  },
  data: function(){   
  }
}

</script>