<template>
    <tr v-bind:class="replyType" v-bind:bgcolor="replyType" style="background: #f00;"> 
               <td class="white-bg">&nbsp;</td> 
          
               <td style="width:376px;">
               [ref#: 42733-42733] I need help picking a camera</td>
               <td>	All Topics</td>
               <td>
                   <small v-bind:class="statusbtnclass">
                     <i v-if="showstatusbtnicon" v-bind:class="statusbtniconclass" ></i>
                     {{statusbtntext}}
                   </small>
               </td>
              
               <td>	Jacob Smith	</td>
             
               <td>2016-07-18 9:36:26 AM</td>
             </tr>
             <tr class="expandedrowcontent" v-bind:class="replyType" v-if="showexpanded1">
              <td class="white-bg">&nbsp;</td>
               <td colspan="7" >
                  <ViewModeContentSummary v-if="viewmodecontent" v-bind:showGetThis = "showgethis" v-bind:showAnswer = "showanswer"></ViewModeContentSummary>
               </td> 
             </tr>
 </template>
 
 <script>
 import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
 import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';
 
 import ViewModeContentSummary from '../components/ViewModeContentSummary.vue';
 import CreateNoteContent from '../components/CreateNoteContent.vue';
 import AgentEmailContent from '../components/AgentEmailContent.vue';
 import CreateTicketContent from '../components/CreateTicketContent.vue';
 import ForwardContent from '../components/ForwardContent.vue';
 import GetThisContent from '../components/GetThisContent.vue';
 
 import CaseProperty from '../components/CaseProperty.vue';
 import StatusButton from '../components/StatusButton.vue';
 
 
 import Multiselect from '@vueform/multiselect'
 
     export default {
       name: 'Expanded Row summary View',
       props: {replyType:String,showGetThis: Boolean, statusBtnText:String, statusBtnClass:String, showstatusBtnIcon:Boolean,statusBtnIconClass:String, typeBtnText:String, typeBtnClass:String, showtypeBtnIcon:Boolean, typeBtnIconClass:String,typeBtnIconClassGroup:String,typeGroupIcon:Boolean, showAnswer:Boolean},
       components: {
         Multiselect,
         InteractionPropertyTab,
         DetailsandattachmentsTabContent,
         ViewModeContentSummary,
         CreateNoteContent,
         AgentEmailContent,
         CreateTicketContent,
         ForwardContent,
         GetThisContent,
         CaseProperty,
         StatusButton,
         
       },
       data: function(){
           return{
             showBCC : true,
             date: new Date(),
             timezone: '',
             showexpanded1: true,
             viewmodecontent : true,
             createnotecontent : false,
             agentemailcontent : false,
             createticketcontent : false,
             forwardcontent  : false,
             getthiscontent : false,
             replyType : this.replyType,
             showGetThis : this.showGetThis,
             statusbtntext:this.statusBtnText, 
             statusbtnclass:this.statusBtnClass, 
             showstatusbtnicon:this.showstatusBtnIcon,
             statusbtniconclass:this.statusBtnIconClass, 
             typebtntext:this.typeBtnText, 
             typebtnclass:this.typeBtnClass,
             showtypebtnicon:this.showtypeBtnIcon, 
             typebtniconclass:this.typeBtnIconClass,
             typebtniconclassgroup: this.typeBtnIconClassGroup,
             typegroupicon : this.typeGroupIcon,
             showgethis : this.showGetThis,
             showanswer : this.showAnswer
           }
       },
       mounted(){
            
            this.emitter.on("show-view-details", Data => {
               this[Data.displaySegment] = true;
               this.viewmodecontent = false;
             });
             this.emitter.on("hide-view-details", Data => {
               this[Data.displaySegment] = false;
               this.viewmodecontent = true;
             });
       }
     }
 </script>